<template>
  <div>
    <p
      v-if="props.isTitleShow"
      class="text-center text-4xl font-bold pb-10 <md:py-3 <md:text-3xl <sm:py-3 <sm:text-2xl"
    >
      {{ t('affiliate.about.title03') }}
    </p>
    <div
      class="bg-$v-blog-container-bg mb-40px rounded-lg mx-auto lg:p-32px <md:p-20px <md:mb-0px @md:p-20px "
    >
      <p class="font-bold mb-10px">{{ t('term.text01') }}</p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        {{ t('term.text02') }}
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        {{ t('term.text03') }}
      </p>
      <ul
        class="list-disc font-thin text-$v-terms-color leading-tight mb-10px ml-20px text-14px"
      >
        <li>{{ t('term.text04') }}</li>
      </ul>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        {{ t('term.text05') }}
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        {{ t('term.text06') }}
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        {{ t('term.text07') }}
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        {{ t('term.text08') }}
      </p>
      <p class="font-bold mb-10px">{{ t('term.text09') }}</p>
      <ol
        class="list-decimal font-thin text-$v-terms-color leading-tight mb-10px ml-20px text-14px"
      >
        <li>
          {{ t('term.text10') }}
        </li>
        <li>
          {{ t('term.text11') }}
        </li>
        <li>
          {{ t('term.text12') }}
        </li>
        <li>
          {{ t('term.text13') }}
        </li>
        <li>
          {{ t('term.text14') }}
        </li>
        <li>
          {{ t('term.text15') }}
        </li>
        <li>
          {{ t('term.text16') }}
        </li>
        <li>
          {{ t('term.text17') }}
        </li>
        <li>
          {{ t('term.text18') }}
        </li>
        <li>
          {{ t('term.text19') }}
        </li>
        <li>
          {{ t('term.text20') }}
        </li>
        <li>
          {{ t('term.text21') }}
        </li>
        <li>
          {{ t('term.text22') }}
        </li>
        <li>
          {{ t('term.text23') }}
        </li>
        <li>
          {{ t('term.text24') }}
        </li>
        <li>
          {{ t('term.text25') }}
        </li>
      </ol>
      <p class="font-bold mb-10px">{{ t('term.text26') }}</p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        {{ t('term.text27') }}
      </p>
      <ul
        class="list-disc font-thin text-$v-terms-color leading-tight mb-10px ml-20px text-14px"
      >
        <li>
          {{ t('term.text28') }}
        </li>
        <li>
          {{ t('term.text29') }}
        </li>
        <li>
          {{ t('term.text30') }}
        </li>
        <li>
          {{ t('term.text31') }}
        </li>
        <li>
          {{ t('term.text32') }}
        </li>
        <li>{{ t('term.text33') }}</li>
      </ul>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        {{ t('term.text34') }}
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        {{ t('term.text35') }}
      </p>
      <p class="font-bold mb-10px">{{ t('term.text36') }}</p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        {{ t('term.text37') }}
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        {{ t('term.text38') }}
      </p>
      <p class="font-bold mb-10px">
        {{ t('term.text39') }}
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        {{ t('term.text40') }}
      </p>
      <div
        class="font-thin text-$v-terms-color leading-tight mb-10px text-14px"
      >
      {{ t('term.text41') }}
        <ul
          class="list-disc font-thin text-$v-terms-color leading-tight mb-10px ml-20px text-14px"
        >
          <li>{{ t('term.text42') }}</li>
          <li>
            {{ t('term.text43') }}
          </li>
          <li>
            {{ t('term.text44') }}
            <ol class="list-alpha ml-20px">
              <li>
                {{ t('term.text45') }}
              </li>
              <li>
                {{ t('term.text46') }}
              </li>
              <li>
                {{ t('term.text47') }}
              </li>
              <li>{{ t('term.text48') }}</li>
              <li>{{ t('term.text49') }}</li>
              <li>{{ t('term.text50') }}</li>
              <li>
                {{ t('term.text51') }}
              </li>
              <li>{{ t('term.text52') }}</li>
            </ol>
          </li>
          <li>
            {{ t('term.text53') }}
          </li>
        </ul>
      </div>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        {{ t('term.text54') }}
      </p>
      <div
        class="font-thin text-$v-terms-color leading-tight mb-10px text-14px"
      >
      {{ t('term.text55') }}
        <ul
          class="list-disc font-thin text-$v-terms-color leading-tight mb-10px ml-20px text-14px"
        >
          <li>
            {{ t('term.text56') }}
          </li>
          <li>
            {{ t('term.text57') }}
          </li>
          <li>
            {{ t('term.text58') }}
          </li>
          <li>
            {{ t('term.text59') }}
          </li>
          <li>
            {{ t('term.text60') }}
          </li>
          <li>
            {{ t('term.text61') }}
          </li>
          <li>
            {{ t('term.text62') }}
          </li>
          <li>
            {{ t('term.text63') }}
          </li>
          <li>
            {{ t('term.text64') }}
          </li>
          <li>
            {{ t('term.text65') }}
          </li>
          <li>
            {{ t('term.text66') }}
          </li>
          <li>
            {{ t('term.text67') }}
          </li>
          <li>
            {{ t('term.text68') }}
          </li>
          <li>
            {{ t('term.text69') }}
          </li>
          <li>
            {{ t('term.text70') }}
          </li>
          <li>
            {{ t('term.text71') }}
          </li>
          <li>
            {{ t('term.text72') }}
          </li>
          <li>
            {{ t('term.text73') }}
          </li>
          <li>
            {{ t('term.text74') }}
          </li>
          <li>
            {{ t('term.text75') }}
          </li>
          <li>
            {{ t('term.text76') }}
          </li>
          <li>
            {{ t('term.text77') }}
          </li>
          <li>
            {{ t('term.text78') }}
          </li>
          <li>
            {{ t('term.text79') }}
          </li>
          <li>
            {{ t('term.text80') }}
          </li>
          <li>
            {{ t('term.text81') }}
          </li>
          <li>
            {{ t('term.text82') }}
          </li>
          <li>
            {{ t('term.text83') }}
          </li>
          <li>
            {{ t('term.text84') }}
          </li>
          <li>
            {{ t('term.text85') }}
          </li>
          <li>
            {{ t('term.text86') }}
          </li>
          <li>
            {{ t('term.text87') }}
          </li>
          <li>
            {{ t('term.text88') }}
          </li>
          <li>
            {{ t('term.text89') }}
          </li>
          <li>
            {{ t('term.text90') }}
          </li>
        </ul>
      </div>
      <!-- <p class="font-bold mb-10px">Account Management</p>
      <div
        class="font-thin text-$v-terms-color leading-tight mb-10px text-14px"
      >
        POWERBTENT reserves the right at its own discretion and at all times, to:
        <ul
          class="list-disc font-thin text-$v-terms-color leading-tight mb-10px ml-20px text-14px"
        >
          <li>
            Decline to open a POWERBTENT Account and/or to close or suspend an
            existing POWERBTENT Account without any explanation whatsoever.
          </li>
          <li>
            Decline to accept deposits without any explanation whatsoever.
          </li>
          <li>Request documents to verify:</li>
          <li>a.the identity of the Account Holder,</li>
          <li>
            b.his/her authorization to use a specific Card or any payment
            method;and/or
          </li>
          <li>c.other facts and information provided by the Account Holder.</li>
          <li>
            Such request may be made at any given moment and POWERBTENT reserves the
            right to suspend an account pending investigation.
          </li>
          <li>
            Transfer and/or license, without prior notice, data regarding an
            Account Holder to any other legal entity, in any country, ultimately
            managed and controlled by Gotech Entertainment, Inc., subject to
            POWERBTENT guaranteeing that the said data at all times is transferred
            and managed in accordance with applicable laws, data protection acts
            and/or similar.
          </li>
          <li>
            Transfer and/or license, without prior notice, the rights and
            liabilities regarding an Account Holder to any other legal entity,
            in any country, ultimately managed and controlled by Gotech
            Entertainment, Inc., subject to POWERBTENT guaranteeing the liabilities
            being honored.
          </li>
          <li>
            Hold and manage funds belonging to Account Holders in accordance
            with generally accepted guidelines for cash management regarding
            such funds; this may include a Financial Institution and/or a
            Payment Solution Provider being entrusted to hold funds in the name
            of and/or for the benefit of Account Holders.
          </li>
          <li>
            Perform its Forfeiture Policy which includes the following
            provisions:
            <ul
              class="list-disc font-thin text-$v-terms-color leading-tight mb-10px ml-20px text-14px"
            >
              <li>
                a) Forfeit and/or confiscate funds available on a POWERBTENT Account
                and/or refuse to honor a claim, in the event that, directly or
                indirectly: (i) the POWERBTENT Rules have been violated; and/or (ii)
                other unauthorized activities have occurred in connection with a
                betting event and/or the operation of a POWERBTENT Account (such as,
                but not limited to, breach of the law or other regulations,
                breach of a third party’s rights, fraud, and cheating)
              </li>
              <li>
                b) Suspend and/or cancel the participation of an Account Holder
                in the games, promotional activities, competitions or other
                services, whenever POWERBTENT is of the opinion that there are
                legitimate concerns that a POWERBTENT Account is, has been, or may
                be used for illegal, fraudulent or dishonest practices;
              </li>
              <li>
                c) Suspend and/or cancel the participation of the Account Holder
                in the Services, and/or forfeit and/or confiscate funds
                available on their POWERBTENT Account if the Account Holder is found
                in direct violation of any provisions of its Terms and
                Conditions such as:
                <ul>
                  <li>Cheating</li>
                  <li>
                    Irregular gaming such as equal, zero or low margin bets or
                    hedge betting in order to circumvent the bonus turnover
                    requirement
                  </li>
                  <li>
                    Unauthorized account takeover which is made through sharing
                    the same device and/or using any other related indicators
                    such as IP address and shared common betting behavior
                  </li>
                  <li>
                    Employing or using a system (including machines, robots,
                    computers, software or any other automated system) designed
                    to defeat or capable of defeating the Client Application
                    and/or Software.
                  </li>
                  <li>
                    Other methods which are in direct violation of the POWERBTENT
                    Terms and Conditions. Decline and/or reverse the withdraw of
                    unspent or otherwise unused deposits until the verification
                    of these funds is deemed satisfactory.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            Hold and manage funds belonging to Account Holders in accordance
            with generally accepted guidelines for cash management regarding
            such funds; this may include a Financial Institution and/or a
            Payment Solution Provider being entrusted to hold funds in the name
            of and/or for the benefit of Account Holders.
          </li>
        </ul>
      </div>
      <p class="font-bold mb-10px">Account Dormancy</p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        If the POWERBTENT account has been suspended in accordance with the clauses
        above, and has already been suspended for more than 6 months or an
        POWERBTENT account has been dormant for more than 6 months since the last
        login time, POWERBTENT reserves the right to charge a monthly administrative
        fee until the account balance reaches zero. The administrative fee
        deducted shall vest in POWERBTENT on deduction from the Suspended Account
        and will cease to form part of the Account Holder’s funds. POWERBTENT
        reserves the right to close the Suspended Account once the account
        reaches zero balance. If there are no funds on the account, the account
        will not be charged and it will be closed immediately.
      </p>
      <div
        class="font-thin text-$v-terms-color leading-tight mb-10px text-14px"
      >
        POWERBTENT warrants and represents at all times to:
        <ul
          class="list-disc font-thin text-$v-terms-color leading-tight mb-10px ml-20px text-14px"
        >
          <li>
            Manage funds belonging to Account Holders in a secure and
            appropriate manner; and/or
          </li>
          <li>
            Manage data regarding an Account Holder in accordance with
            applicable laws, data protection acts and/or similar;
          </li>
          <li>
            Not offer contingencies to customers to proceed to any fund transfer
            between customers’ accounts.
          </li>
        </ul>
      </div>
      <p class="font-bold mb-10px">Betting Rules and Regulations</p>
      <ol
        class="list-decimal ml-20px font-thin text-$v-terms-color leading-tight mb-10px ml-20px text-14px"
      >
        <li>
          All bets on the POWERBTENT website must comply with relevant game rules
          and this agreement.
        </li>
        <li>
          Any typographical error or unintentional human error in the
          announcement of the odds and/or result, POWERBTENT is not responsible for
          the consequences, and POWERBTENT has the right to cancel related bets.
        </li>
        <li>
          Under any circumstances, POWERBTENT reserves the right to refuse any bet
          without giving an explanation.
        </li>
        <li>
          Only bets that comply with the terms and agreements through POWERBTENT
          website or equipment will be considered valid. Regardless of the
          result, any other forms of betting (by letter, mail, fax or other
          means) will not be accepted or deemed invalid. The bet slip takes
          effect as soon as the confirmation message appears on the screen.
        </li>
        <li>
          It is the account holder’s responsibility to be aware about the match
          score and all relevant match information. It is advised that the
          customer verify the match status before placing a bet.
        </li>
        <li>
          The account holder acknowledges that the current score, time elapsed
          and other data provided on the site, while coming from a “live” feed
          provided by a third party is subject to a time delay and/or may be
          inaccurate, and that any bet placed based on this data is entirely at
          the customer’s own risk. POWERBTENT provides this data as-is with no
          warranty as to the accuracy, completeness or timeliness of such data
          and accepts no responsibility for any loss (direct or indirect)
          suffered by the customer as a result of his reliance on it.
        </li>
        <li>
          All bets are considered valid if they meet the following conditions:
          the username and password are entered correctly, and the account
          balance is sufficient.
        </li>
        <li>
          The account holder is responsible for the security of his account and
          this includes all transactions and behaviors that occur through
          his/her account, regardless of whether the transaction is authorized
          or unauthorized.
        </li>
        <li>
          If the supplied betting information is correct, the account holder is
          the only person responsible for this bet. Once the client application
          confirms the bet, the bet cannot be cancelled, withdrawn or changed,
          and the bet slip will be regarded as the valid evidence of the bet.
        </li>
        <li>
          All betting records entered by POWERBTENT will be stored in the POWERBTENT
          transaction database software and will be used as a valid basis for
          any transaction at any time.
        </li>
        <li>
          All bets that show confirmed status in the transaction records of
          POWERBTENT customer accounts are considered valid bets by the company.
        </li>
        <li>
          Except for live betting, POWERBTENT have the right to cancel bets placed
          after the scheduled start of the game.
        </li>
        <li>
          All bets for which the result of the match is already known at the
          time of betting will be considered invalid. POWERBTENT reserves the right
          to cancel such bets.
        </li>
        <li>
          The event information (starting time of the event, red card, score or
          other related information) displayed on the website is for reference
          only
        </li>
        <li>
          The results of the event are confirmed after the event, unless
          otherwise stated in the relevant sport or event rules. POWERBTENT will not
          recognize any amendments or changes made to results for bets that have
          already been settled 72 hours after the event ended. POWERBTENT only
          corrects unintentional human errors or incorrect results within a
          period of 72 hours.
        </li>
        <li>
          If the venue of the event changes, all bets placed at the original
          location will be cancelled, except for neutral ground events which
          will still be considered valid.
        </li>
        <li>
          The winner or loser of the game will be confirmed after the end of the
          game in accordance with relevant rules.
        </li>
        <li>
          POWERBTENT does not recognize interrupted matches and controversial
          proposals.
        </li>
        <li>
          If a match is interrupted and continued within 48h after initial
          kick-off, all open bets will be settled with the final result.
          Otherwise, all undecided bets are considered void.
        </li>
        <li>
          If there is an invalid option in the bet offer, the bet will be
          adjusted and settled according to the remaining valid options.
        </li>
        <li>
          The account holder knows and accepts that all odds and market lines
          are constantly changing and will only be determined at the moment of
          betting. POWERBTENT reserve the right, at its sole and absolute
          discretion, to change the odds, prices, or any information on a bet
          type, market or event at any time.
        </li>
        <li>
          POWERBTENT likewise reserve the right at its sole discretion to either
          void or reject any affected bets at any time or to correct any error
          when by error, omission or mistake the incorrect odds, prices, or any
          information on a bet type, market or event at any time where displayed
        </li>
        <li>
          If the players/teams are displayed incorrectly, POWERBTENT reserve the
          right to void betting.
        </li>
        <li>
          If markets remain open with an incorrect score or Incorrect match
          status which has a significant impact on the prices, POWERBTENT reserve
          the right to void betting.
        </li>
        <li>
          If there are any technical problems in the system, POWERBTENT reserves the
          right to manually change the status of a pending bet to “confirmed”
          when the transaction resumes and as soon as the technical problem has
          been resolved. At that time, the account balance will be adjusted to
          reflect the member's betting at that time.
        </li>
        <li>
          Multiple bets placed on the same event at the same time will not be
          recognized.
        </li>
        <li>
          POWERBTENT has the final decision on any related betting and transactions.
        </li>
        <li>
          The account holder fully accepts the random shuffling of the system
          software in the poker game, and accept other random game transactions.
        </li>
        <li>
          The account holder agrees and accept that WAP network links are not
          always stable. The company only recognizes the bets recorded in the
          system, and POWERBTENT is not responsible for any misplaced bets.
        </li>
        <li>
          Unless listed either in conjunction with the bet offer, or else in the
          Sport Specific rules, all bets should be considered valid for the
          result at the end of the "Regular Time" or "Full Time" only. "Regular
          Time" or "Full time" is defined as interpreted by the official rules
          published by the respective governing association. For example, in
          Football, full time is stipulated to be 90 minutes including injury
          time. Should the governing association decide to stipulate, before the
          start of the event, that the said event is to be played over a
          different duration, this will be treated as being the official rules
          for the event (for example, football matches played with 3 x 30
          minutes or 2 x 40 minute formats). Nonetheless, such occurrence is
          limited to the "regular" playing time and does not include any
          prolongation such as extra time or overtime, unless explicitly stated.
        </li>
        <li>
          "Livebetting" is where it is possible to bet during an ongoing match
          or event. POWERBTENT does not acknowledge or accept any liability
          whatsoever if it not possible to place a bet or the live score update
          is not correct. At all times it is the Account Holder's responsibility
          to be aware of the match and the events surrounding it such as the
          current score, its progression and how much time remains before the
          match is completed. POWERBTENT does not accept any liability for changes
          to the Livebetting schedule or interruption of the Livebetting
          service.
        </li>
        <li>
          Whenever there’s evidence of fraud, game-fixing or rigging, POWERBTENT
          reserves the right to make bets void or withhold payment pending the
          outcome of any investigation.
        </li>
        <li>Opposite betting is not allowed , if bet will be forfeited.</li>
      </ol>
      <p class="font-bold mb-10px">Event-specific Rules</p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        Please refer to our POWERBTENT Sports Betting Game Rules.
      </p>
      <p class="font-bold mb-10px">Transaction Policy</p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        For players who use credit/debit cards or any other type of payment
        method, the bank card name must be the same as the account holder's name
        when registering. If the bank card name is inconsistent with the account
        holder’s name when registering, POWERBTENT reserves the right to refuse any
        transaction requested.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        If the customer has debts to POWERBTENT or any of its members, the customer
        must bear the responsibility of repaying the debts. In terms of payment,
        if a customer is suspected of fraud, including the use of a stolen
        credit card, or other fraud (such as recovering or subverting payment or
        money laundering), POWERBTENT has the right to immediately freeze and/or
        close the customer’s account and confiscate the account balance, and
        publish its information (indicating its identity) to any online
        entertainment websites, banks, credit card companies, and related
        institutions and individuals that have the right to access this
        information. Any costs incurred by POWERBTENT will be incurred by the
        perpetrator involved in the said malicious activities.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        Refund Policy
      </p>
      <ul
        class="font-thin text-$v-terms-color leading-tight mb-10px text-14px list-disc ml-20px"
      >
        <li>
          No refund can be completed once the alleged deposit (including the
          bonus) has been played using the POWERBTENT Services.
        </li>
        <li>
          A refund request will only be considered if it is requested within the
          first twenty-four (24) hours of the alleged transaction, or within
          thirty (30) days if a Player alleges that another individual (or a
          minor) has accessed his/her Player Account or if there was any PAGCOR
          violation incurred.
        </li>
        <li>
          POWERBTENT reserve the right to withhold any refund or reverse transaction
          until the identity of the Player Account User is adequately
          established to its satisfaction, in order to ensure that any payment
          made will be honored after a refund has been made. The player agrees
          to provide, in case demanded, a notarized identification, or any other
          certified identification in accordance with the applicable Philippine
          laws. If such notarized or certified identification is not provided
          within five (5) days of the request, then such refund or reverse
          transaction shall not be affected, the Player Account shall be closed
          and POWERBTENT shall forfeit all funds in the Player Account. Such
          decision shall be final, binding and not subject to appeal.
        </li>
      </ul>
      <p class="font-bold mb-10px">KYC Policy</p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        POWERBTENT Know Your Customer Policy or the KYC Policy is designed to
        prevent and mitigate possible risks of POWERBTENT being involved in any kind
        of illegal activity.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        Both international and local regulations require POWERBTENT to implement
        effective internal procedures and mechanisms to prevent money
        laundering, terrorist financing, drug and human trafficking,
        proliferation of weapons of mass destruction, corruption and bribery and
        to take action in case of any form of suspicious activity from its
        Users.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        KYC Policy covers the following matters:
      </p>
      <ul
        class="font-thin text-$v-terms-color leading-tight mb-10px text-14px list-disc ml-20px"
      >
        <li>Verification procedures.</li>
        <li>Sanctions and PEP lists screening.</li>
        <li>Compliance Officer.</li>
        <li>Monitoring Transactions.</li>
        <li>Risk Assessment.</li>
      </ul>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        Verification procedures
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        Identity verification
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        POWERBTENT’s identity verification procedure requires the User to provide
        POWERBTENT with reliable, independent source documents, data or information
        (e.g., national ID, international passport, bank statement, utility
        bill), one time video call verification and selfie with the valid ID.
        For such purposes POWERBTENT reserves the right to collect User’s
        identification information for the KYC Policy purposes and verify it
        within 24 hours. The following documents will be the acceptable IDs
        according to the PAGCOR Regulatory Framework:
      </p>
      <p class="font-bold mb-10px">List of Accepted IDs</p>
      <ul
        class="font-thin text-$v-terms-color leading-tight mb-10px text-14px list-disc ml-20px"
      >
        <li>National ID</li>
        <li>Driver’s License</li>
        <li>Passport (only accepts Philippine Passport)</li>
      </ul>
      <p class="font-bold mb-10px">Other Accepted IDs</p>
      <ul
        class="font-thin text-$v-terms-color leading-tight mb-10px text-14px list-disc ml-20px"
      >
        <li>UMID</li>
        <li>Philhealth ID</li>
        <li>BIR (TIN)</li>
        <li>Voter’s ID</li>
        <li>SSS (with DOB)</li>
        <li>Postal ID</li>
        <li>
          Overseas Workers Welfare <br />
          Administration (OWWA) ID
        </li>
        <li>Seaman’s Book</li>
        <li>
          Alien Certificate of Registration <br />Immigrant Certificate of
          Registration
        </li>
      </ul>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        POWERBTENT will take steps to confirm the authenticity of documents and
        information provided by the Users. All legal methods for double-checking
        identification information will be used and POWERBTENT reserves the right to
        investigate certain Users who have been determined to be risky or
        suspicious.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        POWERBTENT reserves the right to verify User’s identity on an on-going
        basis, especially when their identification information has been changed
        or their activity seems to be suspicious (unusual for the particular
        User). In addition, POWERBTENT reserves the right to request up-to-date
        documents from the Users, even though they have passed identity
        verification in the past.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        User’s identification information will be collected, stored, shared and
        protected strictly in accordance with the POWERBTENT’s Privacy Policy and
        related regulations.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        Once the User’s identity has been verified, POWERBTENT is able to remove
        itself from potential legal liability in a situation where its Services
        are used to conduct illegal activity.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        Card verification
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        The Users who are intended to use payment cards in connection with the
        POWERBTENT’s Services are required to undergo a verification process in
        accordance with instructions available on the POWERBTENT’s Site.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        Sanctions, Minor, Goverment Officials, PNP/AFP, excluded and PEP lists
        screening. POWERBTENT screens applicants against recognized Sanctions,
        Minor, Goverment Officials, PNP/AFP, excluded and Politically Exposed
        Persons (PEPs) lists. Individuals and legal entities are screened
        against mentioned lists:
      </p>
      <ul
        class="font-thin text-$v-terms-color leading-tight mb-10px text-14px list-disc ml-20px"
      >
        <li>
          On the onboarding stage when the user is submitting the application;
        </li>
        <li>
          On each anti-fraud and AML alerts manually by Compliance Officer;
        </li>
        <li>
          Monthly by running automatically with a script to re-check all DB of
          customers.
        </li>
        <li>
          For the screening process POWERBTENT uses PAGCOR Sanctions, Minor,
          Goverment Officials, PNP/AFP, excluded and Politically Exposed Persons
          (PEPs) database for manual confirmation.
        </li>
      </ul>

      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        POWERBTENT reserves the right to ask for additional proof regarding a
        player's current location by whatever means necessary in compliance with
        the current PAGCOR guideline stating that only individuals residing
        inside the Philippines shall be allowed to play.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        Compliance Officer
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        The Compliance Officer is the person, duly authorized by POWERBTENT, whose
        duty is to ensure the effective implementation and enforcement of the
        KYC Policy. It is the Compliance Officer’s responsibility to supervise
        all aspects of POWERBTENT’s anti-money laundering and counter-terrorist
        financing, including but not limited to:
      </p>

      <ul
        class="font-thin text-$v-terms-color leading-tight mb-10px text-14px list-disc ml-20px"
      >
        <li>Collecting Users’ identification information.</li>
        <li>
          Establishing and updating internal policies and procedures for the
          completion, review, submission and retention of all reports and
          records required under the applicable laws and regulations.
        </li>
        <li>
          Monitoring transactions and investigating any significant deviations
          from normal activity.
        </li>
        <li>
          Implementing a records management system for appropriate storage and
          retrieval of documents, files, forms and logs.
        </li>
        <li>Updating risk assessment regularly.</li>
        <li>
          Providing law enforcement with information as required under the
          applicable laws and regulations.
        </li>
      </ul>

      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        The Compliance Officer is entitled to interact with law enforcement,
        which are involved in prevention of money laundering, terrorist
        financing and other illegal activity.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        Monitoring Transactions
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        The Users are known not only by verifying their identity (who they are)
        but, more importantly, by analyzing their transactional patterns (what
        they do). Therefore, POWERBTENT relies on data analysis as a risk-assessment
        and suspicion detection tool. POWERBTENT performs a variety of
        compliance-related tasks, including capturing data, filtering,
        record-keeping, investigation management, and reporting. System
        functionalities include:
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        Daily check of Users against recognized “black lists” (e.g. PAGCOR
        National Database of Restricted Persons), aggregating transfers by
        multiple data points, placing Users on watch and service denial lists,
        opening cases for investigation where needed, sending internal
        communications and filling out statutory reports, if applicable;
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        Case and document management.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        With regard to the KYC Policy, POWERBTENT will monitor all transactions and
        it reserves the right to:
      </p>

      <ul
        class="font-thin text-$v-terms-color leading-tight mb-10px text-14px list-disc ml-20px"
      >
        <li>
          Ensure that transactions of suspicious nature are reported to the
          proper law enforcement through the Compliance Officer;
        </li>
        <li>
          Request the User to provide any additional information and documents
          in case of suspicious transactions;
        </li>
        <li>
          Suspend or terminate User’s Account when POWERBTENT has reasonable
          suspicion that such User engaged in illegal activity.
        </li>
        <li>
          The above list is not exhaustive and the Compliance Officer will
          monitor Users’ transactions on a day-to-day basis in order to define
          whether such transactions are to be reported and treated as suspicious
          or are to be treated as bona fide.
        </li>
      </ul>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        Risk Assessment
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        POWERBTENT, in line with the PAGCOR compliance, has adopted a risk-based
        approach to combating money laundering and terrorist financing. By
        adopting a risk-based approach, POWERBTENT is able to ensure that measures
        to prevent or mitigate money laundering and terrorist financing are
        commensurate to the identified risks. This will allow resources to be
        allocated in the most efficient ways. The principle is that resources
        should be directed in accordance with priorities so that the greatest
        risks receive the highest attention.
      </p>

      <p class="font-bold mb-10px">Anti-Money Laundering (AML) Policy</p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        Money laundering is defined as the process intended to mask benefits
        derived from serious criminal offenses or criminal conduct, as described
        under Anti-Money Laundering Act of 2001 REPUBLIC ACT NO. 9160, to make
        it appear that such benefits originated from a legitimate source.
        Generally, money laundering is composed of three (3) stages involving
        several transactions which could alert an intermediary such as the
        Company to the money laundering activity:
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        Placement – the physical act of disposing of the cash proceeds drawn
        from illegal activities
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        Layering – the act of separating illicit proceeds from their source by
        creating complex tiers of financial transactions in order to disguise
        the audit trail and provide anonymity
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        Integration – the act of providing an appearance of legitimacy to
        criminally- derived wealth. If the layering process is successful, the
        integration step places the laundered proceeds back into the economy in
        a manner that their re-entry into the financial system has the
        appearance of being normal business funds
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        POWERBTENT Anti-Money Laundering Strategies
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        POWERBTENT endeavors to apply the following principles in order to detect,
        prevent and oppose the use of the Company’s services for money
        laundering and terrorist financing:
      </p>
      <ul
        class="font-thin text-$v-terms-color leading-tight mb-10px text-14px list-disc ml-20px"
      >
        <li>Established and duly appointed Compliance Official</li>
        <li>Know-Your-Customer Process (CDD and EDD)</li>
        <li>Reporting to the AMLC</li>
        <li>Ongoing Employee Training</li>
        <li>Ongoing monitoring and Compliance Testing</li>
        <li>
          Record Keeping and Data Retention in accordance with AML laws and
          regulations
        </li>
        <li>Internal Audit Function.</li>
      </ul>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        Regulatory Compliance
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        POWERBTENT ensures that its business is conducted in conformity with the
        height of ethical standards, that laws and regulations are respected and
        complied with whenever possible, and that service is not provided where
        there is good reason to believe that transactions are associated with
        money laundering activities.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        Partnership with Philippine Law Enforcement Agencies
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        Within the legal constraints relative to client confidentiality, POWERBTENT
        cooperates with law enforcement agencies. This includes taking
        appropriate measures within the law if there are reasonable grounds for
        suspecting money laundering. Any disclosure made in conjunction with the
        Act shall be made to the applicable governing body.
      </p>
      <p class="font-bold mb-10px">Bonus</p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        When an account holder gets a chance to win a bonus from POWERBTENT, any
        bonus must comply with this agreement and the relevant additional terms
        provided.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        POWERBTENT reserves the right to offer a specific bonus to a specific
        customer or group of customers.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        Players must comply with the relevant provisions and restrictions before
        withdrawing the bonus amount from the account, otherwise they will not
        be processed.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        The account holder's withdraw currency must be consistent with the
        deposit currency.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        POWERBTENT reserves the right to withdraw or modify bonuses or promotional
        activities and/or related specific terms and rules at any time, and does
        not assume the responsibility of explaining to customers or third
        parties.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        If an account holder is under self-exclusion, POWERBTENT will make
        reasonable efforts to return the account balance in accordance with
        existing terms and conditions. Please see Responsible Gaming guideline
        for more information.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        Bonus Abuse
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        “Bonus Abuse” includes, but is not limited to:
      </p>
      <ul
        class="font-thin text-$v-terms-color leading-tight mb-10px text-14px list-disc ml-20px"
      >
        <li>
          Breach of terms and conditions of a bonus, free bets or any other
          promotional offer;
        </li>
        <li>The opening of multiple accounts to claim multiple bonuses;</li>
      </ul>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        Where there is a reasonable suspicion that the Account Holder has
        committed or attempted to commit a bonus abuse, either on their own or
        as part of a group, POWERBTENT reserves the right to:
      </p>
      <ul
        class="font-thin text-$v-terms-color leading-tight mb-10px text-14px list-disc ml-20px"
      >
        <li>
          Forfeit the bonus allocated to the Account Holder and any winnings
          from that bonus, and/or;
        </li>
        <li>
          Revoke, deny, or withdraw a bonus offer from the Account Holder,
          and/or;
        </li>
        <li>Block an access to particular products, and/or;</li>
        <li>
          Exclude the Account Holder from any future promotional offers, and/or;
        </li>
        <li>Terminate the Account Holder’s account with immediate effect.</li>
      </ul>

      <p class="font-bold mb-10px">Disclaimer</p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        In the event of POWERBTENT being found liable in any way by a court of law
        and/or a similar authority with legal competence and/or jurisdiction
        over POWERBTENT, then POWERBTENT’s liability is limited to the amount of the
        stake, or the Account Holder’s net winnings, whichever is the lesser.
        Alternatively, when relevant and applicable, the amount recorded in the
        POWERBTENT Account or the amount transferred into or out of the POWERBTENT
        Account, whichever is the lesser.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        POWERBTENT is not affiliated or connected with sports teams, event
        organizers, or players displayed on its websites, and does not claim any
        intellectual property rights relating to their name and image, which are
        used strictly in order to provide the Services.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        POWERBTENT makes no representation or warranty, explicit or implicit, as to
        the legal rights of the Account Holder to participate in the Services,
        nor shall any of POWERBTENT’s employees, licensees, distributors,
        wholesalers, affiliates, subsidiaries, advertising, promotion or other
        agencies, media partners, agents or retailers have the authority to make
        any such representation or warranty. The Account Holder shall not use
        the Services, open, use or reuse a POWERBTENT Account, enter the Website,
        nor accept any Prize if the Account Holder does not fully understand,
        agree to, wish to become a party to, and comply with, without exception,
        all the POWERBTENT Rules contained herein, and as these may be amended from
        time to time.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        POWERBTENT does not acknowledge or accept any liability for damage and/or
        losses to an Account Holder and/or a third party caused directly and/or
        indirectly due to the Account Holder:
      </p>
      <ul
        class="font-thin text-$v-terms-color leading-tight mb-10px text-14px list-disc ml-20px"
      >
        <li>
          making deposits to his POWERBTENT Account via a third party’s Card or
          account;
        </li>
        <li>
          requesting withdrawals from his POWERBTENT Account to a third party’s
          account;
        </li>
        <li>
          providing incorrect details of his personal account for the purpose of
          withdrawals from his POWERBTENT Account;
        </li>
        <li>
          allowing third parties to use his POWERBTENT Account to make deposits to
          or withdrawals from his POWERBTENT Account.
        </li>
      </ul>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        POWERBTENT does not acknowledge or accept any liability whatsoever for
        damage and/or losses to an Account Holder and/or a third party caused
        directly and/or indirectly due to any:
      </p>
      <ul
        class="font-thin text-$v-terms-color leading-tight mb-10px text-14px list-disc ml-20px"
      >
        <li>
          Mistake, misprint, misinterpretation, mishearing, misreading,
          mistranslation, spelling mistake, fault in reading, transaction error,
          technical failure, technical hazard, registration error, manifest
          error, cancellation of a game for any reason, and/or any other similar
          event;
        </li>
        <li>Violation of the POWERBTENT Rules;</li>
        <li>Collusion and/or criminal actions;</li>
        <li>Advice provided by POWERBTENT;</li>
        <li>
          Failure of POWERBTENT’s central computer system or any part thereof;
          delays, losses, errors or omissions resulting from failure of any
          telecommunications or any other data transmission system; and/or
        </li>
        <li>
          Financial risk and loss, including but not limited to variances in
          exchange rates;
        </li>
      </ul>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        All information displayed on the Website (including but not limited to
        scores, results, time of the event, statistics, editorial text etc.) is
        provided for information purposes only, and not intended to constitute
        professional advice of any kind. Neither POWERBTENT nor any of its
        independent providers is liable for any information errors,
        incompleteness, inaccuracy or delays, or for any actions taken in
        reliance on information contained therein.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        The Account Holder shall hold POWERBTENT, its employees, officers,
        directors, licensees, distributors, wholesalers, affiliates,
        subsidiaries, advertising, promotion or other agencies, media partners,
        agents and retailers harmless and shall fully indemnify the same from
        any and all costs, expenses, liabilities and damages whatsoever that may
        arise as a result of Account Holder's:
      </p>
      <ul
        class="font-thin text-$v-terms-color leading-tight mb-10px text-14px list-disc ml-20px"
      >
        <li>Entry, use or reuse of the Website;</li>
        <li>Use of any Website material;</li>
        <li>
          Entry, use or reuse of the servers used to provide the Services;
        </li>
        <li>Advice provided by POWERBTENT;</li>
        <li>Participation in the games, chat functionalities and Services;</li>
        <li>The acceptance of any Prize.</li>
      </ul>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        Under no circumstances, including but not limited to negligence, shall
        POWERBTENT, its Software and Client application, software suppliers,
        subsidiary companies, or affiliates be liable for any direct, indirect,
        incidental, special or consequential damages resulting from the use of
        or the inability to use POWERBTENT's or its suppliers’ products, Client
        Application and/or materials. The Account Holder specifically
        acknowledges and agrees that POWERBTENT is not liable for any defamatory,
        offensive or illegal conduct of any Account Holder. If the Account
        Holder is dissatisfied with any Service, or with any of the present
        POWERBTENT Rules, the Account Holder's sole and exclusive remedy is to
        discontinue using the Services.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        The Account Holder understands, acknowledges and accepts that any
        resemblance of names, circumstances or conditions used, depicted,
        described or suggested in the games operated on the Website with reality
        is entirely coincidental and unintended.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        The Account Holder understands that POWERBTENT reserves the right to change
        or remove any of its Services at any time.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        The Account Holder shall review the POWERBTENT Rules as posted on the
        Website on a regular basis, and not less than once a month.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        An account holder can access content played by third parties from the
        website (for example: streaming media). The account holder consequently
        acknowledges that this content does not belong to POWERBTENT and is provided
        by a third party. When accessing content on the website, the account
        holder or a visitor is at their own risk. POWERBTENT does not control the
        graphics, sounds or images provided by such content, but is for viewing
        purposes only. POWERBTENT expressly disclaims any and all responsibilities
        related to such content.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        In any case, POWERBTENT is not responsible for any losses caused by poor or
        delayed satellite reception, network interruption, or personal errors,
        negligence, or misunderstanding of the website content when using the
        website services.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        The company does not provide guarantees and assumes no liability for
        delays, breach of contract, or non-service provision due to third-party
        services provided by the company's website and information (such as
        wireless communication providers, etc.).
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        Any direct, indirect, major or ordinary loss or economic loss caused by
        customers accessing or using the website services, software, information
        services, or downloading, installing, or using the software, regardless
        of whether POWERBTENT has been notified of its possibility, POWERBTENT and
        related partners Companies, partners, partners, employees, and agents
        will not be liable.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        If there is an inconsistency between the match result on the client's
        computer accessories and the match result of POWERBTENT’s server, the result
        displayed by the server will be the final match result. In addition,
        customers also agree that POWERBTENT has the final right to interpret the
        game rules and betting status of customers participating in its
        services.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        POWERBTENT does not provide any express or implied guarantees for the
        services currently provided, nor does it provide any guarantees or
        explanations for the quality, suitability, completeness or accuracy of
        its services.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        POWERBTENT does its best to provide customers with the highest quality,
        safest and reliable services. However, it does not guarantee the
        stability, timeliness and accuracy of the services we provide, or that
        errors will be corrected in time or that the website will not be
        interfered by viruses or worms.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        POWERBTENT has the absolute right to temporarily or permanently suspend,
        terminate, modify, delete or add service content immediately, without
        notifying the customer, and POWERBTENT is not responsible for any losses
        arising therefrom.
      </p>

      <p class="font-bold mb-10px">Breach of Agreement and Claim</p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        If the account holder violates this agreement or part of its terms,
        POWERBTENT has the right to take appropriate corresponding measures,
        including terminating the agreement, immediately intercepting and
        preventing the access from its website and using its services, closing
        the account, confiscation of account funds and/or taking necessary
        measures legal proceedings.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        The account holder thereby guarantees that if the following
        circumstances occur to POWERBTENT and its shareholders, employees, staff,
        license holders, licensees, partners and affiliates; the account holder
        will be fully responsible for compensation for losses, costs, expenses,
        attorney fees and other charges, etc., and will protect their legitimate
        rights and interests from liability and negative influences.
      </p>
      <ul
        class="font-thin text-$v-terms-color leading-tight mb-10px text-14px list-disc ml-20px"
      >
        <li>
          Violation of the agreement or part of the agreement by the account
          holder;
        </li>
        <li>
          Violation of laws or regulations or affect the legal rights of third
          parties by the account holder;
        </li>
        <li>
          Other negative results caused by the account holder’s use of POWERBTENT’s
          website services, or other people's use of account information to log
          in, regardless of whether authorized or not.
        </li>
      </ul>

      <p class="font-bold mb-10px">Priority</p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        The rules of the game and any other terms of agreement regarding access
        to and use of the website services will form a complete agreement.
        Unless there are express provisions, if the game rules conflict with any
        agreement terms or other parts of the agreement about accessing and
        using the website services, the game rules will prevail.
      </p>

      <p class="font-bold mb-10px">Governing Law</p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        This agreement and related clauses are subject to and supervised by the
        relevant laws of the Republic of the Philippines. Regarding any
        litigation, dispute or disagreement on this clause and any matters
        arising therefrom, the relevant courts in the Republic of the
        Philippines have the sole right of interpretation and ruling. Each party
        irrevocably waives the following rights: deny the Republic of the
        Philippines trial, or claim that the Republic of the Philippines court
        does not have jurisdiction, or that the principle of court inconvenience
        should be adopted.
      </p>

      <p class="font-bold mb-10px">Issue Resolution</p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        If any individual wants to complain about any aspect of POWERBTENT's
        products or services, they are advised to contact the POWERBTENT customer
        service team.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        In the initial stage of an account’s holder complaint, POWERBTENT will
        investigate the matter in a fair and objective manner, and will provide
        practical results and final decisions as much as possible.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        The customer might be asked to provide additional information, data or
        appropriate evidence to help in handling the said complaint.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        Alternative dispute resolution – All complaints cannot be resolved
        during the appeal process and can be submitted to relevant independent
        agencies for adjudication.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        Any disputes that refer to relevant independent institutions will be
        handled by specific relevant independent institutions in accordance with
        the law and the dispute resolution process. The resulting decision is
        final and executory.
      </p>

      <p class="font-bold mb-10px">Other Terms</p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        The official Terms and Conditions have been drafted in English.
        Translations into other languages are made as a service and in good
        faith. In the event of discrepancy between the English version and a
        translation, the English version shall supersede and prevail.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        If a clause in the agreement is ruled as illegal, invalid or
        unenforceable in a certain legal category, it will not affect the
        application of that clause in other legal areas, nor will it affect the
        application of other clauses in the agreement in that legal category, or
        other clauses. Application in other legal areas.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        The title of the agreement is for reference only, and it does not affect
        the composition or interpretation of any clause.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        If POWERBTENT fails to perform its obligations in accordance with the
        provisions of the agreement due to the following reasons (including
        natural disasters, government control, war, riots, transmission
        interruption, system crash, telecommunications or broadband service
        interruption, power interruption, etc.), it will not constitute a breach
        of contract.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        In this agreement, POWERBTENT's failure or delay to exercise or perform any
        rights, remedies, privileges do not mean that POWERBTENT has waived this
        privilege.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        POWERBTENT reserves the right to transfer the agreement or part of the
        content at any time without notice. However, the account holder or any
        visitor has no right to transfer POWERBTENT rights or obligations with
        respect to this agreement.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        This agreement cannot generate or grant any rights or other benefits to
        third parties unrelated to this agreement.
      </p>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        This agreement cannot be created or cannot be regarded as creating a
        cooperation, agency, trust or joint venture relationship between POWERBTENT
        and the account holder.
      </p>
      <div ref="bottomRef"></div>
      <p class="font-thin text-$v-terms-color leading-tight mb-10px text-14px">
        This agreement is the latest and complete version of the website service
        usage agreement between the account holder or a visitor and POWERBTENT, and
        it will replace the previous version.
      </p> -->
    </div>
  </div>
</template>
<script setup lang="ts">
import { useIntersectionObserver } from "@vueuse/core"
const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    isTitleShow?: Boolean
  }>(),
  {
    isTitleShow() {
      return true
    },
  }
)

const emit = defineEmits(["onScrollBottom"])

const bottomRef = ref(null)
const { stop } = useIntersectionObserver(
  bottomRef,
  ([{ isIntersecting }], observerElement) => {
    emit("onScrollBottom", isIntersecting)
  }
)
</script>
